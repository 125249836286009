@import "variables";

.root {
  height: auto;
  padding-bottom: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
  height: 51px;
}

.pageTitle {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.backButton {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 11px 16px;
}

.search {
  min-width: 340px;
  margin-right: 24px;

  :global {
    .ant-select-selector {
      border: unset !important;
      border-radius: 8px !important;
      height: 48px !important;
    }

    .ant-input-prefix {
      margin-right: 12px;
    }

    .ant-select-selection-search {
      height: 40px;
    }

    .ant-select-selection-placeholder {
      line-height: 40px !important;
    }

    .ant-select-selection-search-input {
      border-radius: 8px;
      border: 1px solid #dee2e6;
    }

    input {
      padding: 0 20px;
      height: 40px !important;
      border: none;
      border-radius: 8px;
      color: $color-text;
      background-color: #fff;
      // min-width: 340px;
      width: auto;
      &::placeholder {
        color: $color-text-5;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.input {
  :global {
    input {
      padding: 0 20px;
      height: 40px !important;
      border: none;
      border-radius: 8px;
      color: $color-text;
      background-color: #fff;
      min-width: 480px;
      width: auto;
      &::placeholder {
        color: $color-text-4;
      }
    }
  }
}

.dropdown {
  padding: 16px;
  border-radius: 8px;
  :global {
    .ant-select-item {
      min-height: 50px;
      padding: 16px;
      font-size: 15px;
      line-height: 17.63px;
    }
  }
}

.plusIcon {
  margin-right: 12px;
  color: white;
  width: 14px;
  height: 14px;
}
