@import "variables";

.container {
  height: 100%;
  width: 100%;
  flex-direction: row;
}
.content {
  padding: 48px;
  overflow: auto;
  height: calc(100% - 96px);
}
