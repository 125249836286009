.component {
	
}
.logo {
	width: 24px;
	height: 32px;
  &.withName {
    width: auto;
    height: 20px;
  }
}