@import 'variables';

.component {
  padding: 16px;
  display: flex;
  flex-direction: column;
	align-items: stretch;
}
.acronym {
  text-transform: uppercase;
}
.link {
  height: 32px;
  line-height: 32px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 8px;
  color: $color-text-5;
  &:hover,
  &:global(.active) {
    background-color: #D5E6FB;
    color: #2F80ED;
  }
}
.icon {
  margin-right: 12px;
}
.button {
	color: #fff;
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	font-weight: bold;
  display: block;
  width: 100%;
  text-align: center;
  align-self: center;
	&:hover {
		color: #fff !important;
	}
}