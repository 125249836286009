.component {
  color: #fff;
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #fff;
  }
}

.small {
  height: 40px;
  font-size: 15px;
  padding-left: 22px;
  padding-right: 22px;
  line-height: 17.63px;
  font-weight: 400;
}

.outlined {
  background: transparent;
  border: 1px solid #CED4DA;
  color: #000;
  font-weight: normal;
  &:hover {
    color: #000;
    background: transparent;
  }
}