@import "variables";

.link {
  height: 32px;
  line-height: 32px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 0 8px;
  color: $color-text-5;
  &:hover {
    cursor: pointer;
    background-color: #d5e6fb;
    color: #2f80ed;
    .editIcon {
      display: block;
      &.disabled {
        display: none;
      }
    }
  }
  &.active {
    cursor: pointer;
    background-color: #d5e6fb;
    color: #2f80ed;
  }
  .editIcon {
    display: none;
    height: 16px;
    width: 16px;
  }
}

.icon {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.acronym {
  text-transform: uppercase;
}

.nameInput {
  width: 212px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #2f80ed;
  box-shadow: 0px 4px 16px rgba(45, 46, 64, 0.25);
}
