@import "variables";

.root {
  display: flex;
}

.component {
  height: 40px;
  padding: 11px 15px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;

  &::placeholder {
    color: $color-text-4;
  }
}

.menuContainer {
  padding: 14px;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(45, 46, 64, 0.25);
  display: flex;
  flex-direction: column;
  max-height: 323px;
  min-width: 338px;
  overflow-y: scroll;
}

.menu {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 13px;
  border: 1px solid #CED4DA;
  border-radius: 8px;
  margin-right: 11px;
  min-width: 173px;
  .label {
    font-weight: 600;
    color: #15141a;
    font-size: 15px;
    line-height: 18px;
  }

  &.activeMenu {
    border: unset;
    background-color: rgba(47, 128, 237, 0.15);

    .label {
      color: #1b6cd9;
    }
  }
}

.filterIcon {
  font-size: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  color: #000000;
  &.activeFilter {
    color: #1b6cd9;
  }
}

.option {
  display: flex;
  align-items: center;
}

.menuItem {
  cursor: pointer;
  padding: 9px 7px;
  border-radius: 4px;

  &:hover {
    background: #f5f5f5;
  }
}

.selectedMenuItem {
  &:first-child {
    margin-top: 20px;
  }
  &:hover {
    background: rgba(47, 128, 237, 0.25);
  }
}

.tag {
  border-radius: 8px;
  color: #15141a;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 14.1px;
  font-weight: 700;
}

.selectedTag {
  color: #fff;
}

.statusIcon {
  margin-right: 8px;
  color: #6c757d;
}

.selecedTagTitle {
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  color: #6c757d;
  margin-bottom: 4px;
}

.selectedContainer {
  margin-bottom: 16px;
}
