@import "variables";

.component {
  // overflow: hidden;
  // margin: 10px;
  border-radius: 16px;
  max-width: 740px;
  margin: 0 auto;
  padding: 40px;
  :global {
    .ant-card-body {
      height: 100%;
    }
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-text-5;
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 32px;
  .name {
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    color: $color-text;
  }
  .role {
    margin: 4px 0;
  }
  .email {
  }
}

.title {
  font-size: 36px;
  line-height: 42.3px;
}

.content {
  height: auto;
  max-height: 100%;
  grid-column: 2;
  overflow: auto;
}
.cards {
  overflow: auto;
  padding: 32px 0;
  border-top: 1px solid $color-text-2;
  border-bottom: 1px solid $color-text-2;
}
.card {
  margin-bottom: 20px;
}
.formItem {
  margin: 0 0 24px;
  position: relative;
}
.input {
  padding: 0 20px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;
  &::placeholder {
    color: $color-text-4;
  }
  :global {
    .ant-select-selector,
    .ant-select-selection-item {
      background: transparent !important;
      border: none !important;
      padding: 0 !important;
      height: 100% !important;
      line-height: 40px !important;
    }
  }
}
.openChangePassword {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 32px;
}
.backButton {
  background: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  border: 2px solid $color-text;
  color: $color-text;
}
.submitButton {
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  margin-right: 16px;
}
