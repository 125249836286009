@import "variables";

.component {
  height: 40px;
  width: 170px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;

  &::placeholder {
    color: $color-text-4;
  }
}

.menuContainer {
  padding: 14px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.menu {
  min-width: 182px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #6c757d;
  border-radius: 8px;
  .label {
    font-weight: 500;
    margin-right: 8px;
    color: #6c757d;
    flex-grow: 1;
  }
}

.filterIcon {
  font-size: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  color: #6c757d;
}

.option {
  display: flex;
  align-items: center;
}
