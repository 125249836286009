@import 'variables';

.component{
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
}

.contactContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactContent{
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 15px;
  line-height: 19.2px;
  color: $color-text
}

.contactButton {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 12px;
  font-weight: 700;
}