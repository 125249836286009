@import 'variables';

.buttons {
  margin-top: 16px;
  display: flex;
  :global {
    img {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
  }
}
.uploadButton {
  display: flex;
  align-items: center;
  background: #fff;
	border-radius: 8px;
	height: 40px;
	font-size: 16px;
  line-height: 40px;
  font-weight: 500;
	padding: 0 24px;
	font-weight: bold;
	border: 1px solid $color-text;
  color: $color-text;
}
.uploadIcon {
  margin-right: 8px;
  color: #27AE60;
}
.recordButton {
  display: flex;
  align-items: center;
  margin-left: 16px;
  background: #fff;
	border-radius: 8px;
	height: 40px;
	font-size: 16px;
  line-height: 40px;
  font-weight: 500;
	padding: 0 24px;
	font-weight: bold;
	border: 1px solid $color-text;
  color: $color-text;
}
.thumbnail {
  height: 80px;
  width: 80px;
  border-radius: 0;
  border: none;
  background-image: url('./ThumbnailBorder.png');
  background-repeat: no-repeat;
  background-size: 100%;
  :global {
    .label {
      display: none;
    }
    .ant-upload-select {
      background: none;
    }
    .ant-upload {
      font-size: 32px;
    }
  }
}
.thumbnailLabel {
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 16px;
  color: $color-text-5;
}

.thumbnails{
  display: flex;
  margin-bottom: 4px;
  align-items: center;
}

.screenshotContainer{
  margin-right: 4px;
  border: 2px solid transparent;
  border-radius: 4px;
  &:global(.selected) {
		border: 2px solid #2F80ED;
	}
}