@import "variables";

.container {
  display: flex;
  flex-direction: column;
}

.component {
  font-size: 24px;
  height: auto;
  z-index: 100;
  color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 80px auto 80px;
  &.withUser {
    .content {
      grid-template-columns: auto 1fr auto auto;
    }
  }
  &.public {
    background-color: #fff;
    padding: 0 16px;
    .content {
      grid-template-columns: auto 1fr auto;
    }
    .logOut {
      display: none;
    }

    @include phone {
      grid-template-columns: 0px auto 0px;
    }
  }
}
.content {
  height: 72px;
  grid-column-start: 2;
  grid-column-end: 3;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: auto 1fr auto auto auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

@include phone {
  .content {
    grid-column-gap: 20px;
  }
}
.title {
  color: #3498db;
  font-size: 24px;
  margin-top: -2px;
  display: inline-block;
}
.lastLetters {
  color: #f39c12;
}
.menu {
  display: flex;
  justify-content: center;
  margin: 0;
  list-style-type: none;
  :global {
    li {
      padding: 0 20px;
    }
    a {
      text-align: center;
    }
  }
}

.role {
  font-size: 12px;
}

.logOut {
  font-size: 16px;
  color: #333;
  text-transform: uppercase;
  cursor: pointer;
}

.topActionContainer {
  margin-left: auto;
  display: flex;
}

.stepContainer {
  height: 104px;
  grid-column-start: 2;
  grid-column-end: 3;
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: white;
}

.stepContainer::-webkit-scrollbar {
  display: none;
}

.stepContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@include phone {
  .stepContainer {
    white-space: nowrap;
    overflow: scroll;
    background-color: transparent;
  }
}

.stepComponent {
  font-size: 24px;
  height: auto;
  z-index: 100;
  color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: 80px auto 80px;
  &.withUser {
    .content {
      grid-template-columns: auto 1fr auto auto;
    }
  }
  &.public {
    background-color: white;
    padding: 0 8px;
    .content {
      grid-template-columns: auto 1fr auto;
    }
    .logOut {
      display: none;
    }
  }
}

@include phone {
  .stepComponent {
    background-color: white;
    grid-template-columns: 0px auto 0px;
    &.public {
      background-color: $color-div-3;
    }
  }
}
