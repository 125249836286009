@import "variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  overflow: auto;
  height: 100%;
  @include phone {
    padding: 0px;
    width: 100%;
  }
}

.component {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include phone {
    max-width: unset;
    border-radius: unset;
    border: unset;
  }
}

.fullHeight {
  height: 100%;
}

.card {
  width: 100%;
  padding: 24px;
  background: $whitecolor;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  @include phone {
    border: unset;
    border-radius: unset;
    height: 100%;
  }
}

.title {
  text-align: center;
}

.hint {
  font-weight: normal;
  font-size: 15px;
  line-height: 17.63px;
  text-align: center;
  color: $color-text-5;
  margin-bottom: 0;
  :global {
    b {
      color: $whitecolor;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.justifyBetweenAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.p2 {
  padding: 16px;
}

.actionButton {
  font-size: 15px;
  line-height: 22.5px;
  font-weight: 700;
}

.backButton {
  color: #2f80ed;
  background: rgba(47, 128, 237, 0.2);
  font-size: 15px;
  line-height: 22.5px;
  font-weight: 700;
}

.actionsContainer {
  margin-top: 24px;
  @include phone {
    margin-top: auto;
  }
}

.bgWhite {
  @include phone {
    background-color: white;
  }
}

.watchMemoStep {
  height: 100%;
  display: flex;
  flex-direction: column;
}
