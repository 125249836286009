@import "variables";

.component {
  background-color: #f8f8f8;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
}
.content {
  display: grid;
  grid-template-rows: 1fr;
}

:global() {
  /* width */
  *::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #a0a1aa;
    border-radius: 4px;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@include touch {
  .component {
    // padding-top: 0;
  }
  .subHeader {
    box-sizing: content-box;
  }
}
