@import "variables";

.container {
  width: 600px !important;
  max-width: 100vw;
  grid-column: 2;
  border-radius: 16px;
  :global {
    .ant-modal-content {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 0;
      background-color: transparent;
    }
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
    .ant-select-dropdown {
      display: none !important;
    }
  }
}

.header {
  margin-bottom: 32px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  border: 2px solid $color-text;
  color: $color-text;
  margin-right: 16px;
}

.contentCard {
  margin-bottom: 12px;
  padding: 40px;
  border-radius: 10px;
  :global {
    .ant-card-body {
      border-radius: 10px;
    }
  }
}

.closeIcon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  margin: auto;
}

.description {
  text-align: center;
}

.actionsContainer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.actionCard {
  width: 155px;
  height: 155px;
  padding: 36.44px 0px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    color: $color-nav-text-active !important;
    .actionIcon {
      color: $color-nav-text-active;
    }
    .actionLabel {
      color: $color-nav-text-active;
    }
  }
}

.actionIcon {
  width: 48px;
  height: 49px;
  color: #000;
  margin-bottom: 16px;
}

.actionLabel {
}
