@import 'variables';

.component {
  padding: 48px;
	display: grid;
	align-items: flex-start;
	grid-template-columns: 1fr minmax(auto, 556px) 1fr;
	:global {
		.ant-form-item-label {
			text-align: left;
		}
	}
}
.content {
	grid-column-start: 2;
	grid-column-end: 3;
}
.header {
  display: grid;
  grid-template-columns: 1fr auto;
}
.formItem {
	
}