.component {
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  &:empty {
    display: none;
  }
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}