@import "variables";

.container {
  // padding: 48px;
  // display: grid;
  // align-items: flex-start;
  // grid-template-columns: 1fr minmax(auto, 1112px) 1fr;
}
.component {
  height: 100%;
  padding: 0px 0px 0px 35px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
  height: 51px;
}
.main {
  display: grid;
  grid-template-columns: 320px 320px 320px;
  grid-auto-rows: 330px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  overflow: auto;
  padding-right: 40px;
}

@media (min-width: 1800px) {
  .main {
    grid-template-columns: 320px 320px 320px 320px;
  }
}


@media (max-width: 1400px) {
  .main {
    grid-template-columns: 270px 270px 270px;
  }
}

@media (max-width: 1250px) {
  .main {
    grid-template-columns: 270px 270px;
  }
}

@media (max-width: 960px) {
  .main {
    grid-template-columns: 320px;
  }
}
