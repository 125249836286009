@import "variables";

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto 56px 740px 56px auto;
  padding: 0 10px;
  overflow: auto;
  height: 100%;
}
.formContainer {
  align-self: flex-start;
  max-width: 100%;
  grid-column: 3;
  box-shadow: unset;
  :global {
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
  }
}

.playerCard {
  justify-self: center;
  width: 100%;
  max-width: 740px;
  grid-column: 2;
  display: block;
  align-items: center;
  box-shadow: 0px 24px 24px -8px $box-shadow-color;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 12px;
}

.questionsContainer {
  margin-top: 48px;
  border-radius: 12px;
  box-shadow: 0px 24px 24px -8px $box-shadow-color;
}

.toolbar {
  grid-column: 4;
  grid-row: 1;
  align-self: flex-start;
  position: relative;
  margin-left: 40px;
}

.submitButton {
  justify-self: center;
	grid-column: 2;
	display: flex;
	align-items: center;
  background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
	color: #fff;
	align-self: center;
	width: auto;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
}

.statusModal {
	:global {
    .ant-modal-content {
      border-radius: 16px;
		}
		.ant-modal-body {
      padding: 40px;
		}
	}
}

.primaryButton {
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
	color: #fff;
	align-self: center;
	width: auto;
	margin-left: 8px;
	margin-right: 8px;
}

.embed {
  border-radius: 0;
}

.centered {
  grid-column: 3;
  justify-self: center;
  margin-top: 48px;
  display: flex;
  align-items: center;
}
.deletedWarning {
  margin-bottom: 32px;
}
.title {
  margin-bottom: 32px;
}
.desc {
  font-size: 17px;
  line-height: 22px;
  color: $color-text-5;
}
.formItem {
  margin: 0 0 32px;
  position: relative;
}
.input {
  padding: 10px 20px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;
  &::placeholder {
    color: $color-text-4;
  }
}
.projectSelect {
  min-width: 220px;
  width: auto !important;
}
.deleteModal {
  text-align: center;
  width: 740px !important;
  max-width: 100%;
  :global {
    .ant-modal-content {
      border-radius: 16px;
    }
    .ant-modal-body {
      // border-radius: 16px;
      padding: 40px;
      box-shadow: 0px 16px 32px rgba(45, 46, 64, 0.1);
    }
    h2 {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      margin: 0;
      color: $color-text;
    }
    p {
      margin: 32px 0;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      color: $color-text-5;
    }
  }
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .deleteButton {
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    background-color: #eb5757;
    border-radius: 8px;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    margin-right: 48px;
  }
  .cancelButton {
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    background-color: #fff;
    border-radius: 8px;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: $color-text;
    border: 2px solid $color-text;
  }
}

.loading {
  width: 100%;
  max-width: 740px;
  grid-column: 3;
  justify-self: center;
  display: flex;
  align-items: center;
}
