@import 'variables';

:global([class*="thumbnail"]) {
  background-position: center;
  background-size: cover;
}
:global(.thumbnail-1) {
  background-image: url("/thumbnails/Pattern1.png");
}
:global(.thumbnail-2) {
  background-image: url("/thumbnails/Pattern2.png");
}
:global(.thumbnail-3) {
  background-image: url("/thumbnails/Pattern3.png");
}
:global(.thumbnail-4) {
  background-image: url("/thumbnails/Pattern4.png");
}
:global(.thumbnail-5) {
  background-image: url("/thumbnails/Pattern5.png");
}
:global(.thumbnail-6) {
  background-image: url("/thumbnails/Pattern6.png");
}
:global(.thumbnail-7) {
  background-image: url("/thumbnails/Pattern7.png");
}
:global(.thumbnail-8) {
  background-image: url("/thumbnails/Pattern8.png");
}
:global(.thumbnail-9) {
  background-image: url("/thumbnails/Pattern9.png");
}
:global(.thumbnail-10) {
  background-image: url("/thumbnails/Pattern10.png");
}
:global(.thumbnail-11) {
  background-image: url("/thumbnails/Pattern11.png");
}

.component {
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  overflow: hidden;
  &.covered {
    position: relative;
  }
  &:not(.covered) {
    &:global([class*="thumbnail"]) {
      background-image: none;
    }
  }
  &:not(.playable) {
    pointer-events: none;
    .coverButton {
      display: none;
    }
  }
}
.coverBg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 372px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // &::after {
  //   content: '';
  //   background: rgba(0, 0, 0, 0.45);
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }
}
.coverButton {
  position: relative;
  z-index: 1;
  height: auto;
  background: #2F80ED;
  border-radius: 50%;
  padding: 14px;
  height: 56px;
  width: 56px;
  &:hover,
  &:active {
    background: #2F80ED;
  }
  :global {
    img {
      width: 24px;
      height: 28px;
    }
  }
}

.downloadButton {
  position: absolute;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 1;
}

.bottomBarShadow {
  width: 100%;
  bottom: 0;
  position: absolute;
  height: 80px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
}


.playerWrapper {
  width: 100% !important;
  max-width: 708px;
  height: 372px !important;
  :global {
    iframe,
    video {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
.playerWrapper video::-webkit-media-controls {
  display: none;
}
.playerWrapper video::-webkit-media-controls-timeline,
.playerWrapper audio::-webkit-media-controls-timeline {
    display: none;
}

@include phone {
  .coverBg {
    height: 185px;
  }
  .playerWrapper {
    height: 185px !important;
  }
}
