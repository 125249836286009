@import "variables";

.container {
  width: 600px !important;
  max-width: 100vw;
  grid-column: 2;
  border-radius: 16px;
  :global {
    .ant-modal-content {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 0;
      background-color: transparent;
    }
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
    .ant-select-dropdown {
      display: none !important;
    }
  }
}

.header {
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: column;
}

.contentCard {
  margin-bottom: 12px;
  padding: 40px;
  border-radius: 10px;
  :global {
    .ant-card-body {
      border-radius: 10px;
    }
  }
}

.successMessage {
  :global {
    .ant-notification-notice-message {
      margin-bottom: 0;
    }
  }
}

.successMessageContainer {
  display: flex;
}

.successIcon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.closeIcon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actionCard {
  margin-top: 40px;
  width: 128px;
  height: 128px;
  padding: 36.44px 0px;
  background: #2f80ed33;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    background: #2f80ed33;
  }
}

.actionIcon {
  width: 48px;
  height: 49px;
  color: $color-nav-text-active;
  margin-bottom: 16px;
}

.actionLabel {
  color: $color-nav-text-active;
}

.description {
  margin-top: 16px;
}

.submitContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
