@import 'variables';

.component {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0;
}
.button {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background-color: #fff;
  color: $color-text;
  margin-bottom: 25px;
  text-align: center;
  line-height: 56px;
  padding: 0;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  &:hover {
    color: #333;
    background: #fff;
  }
}
.delete {
  background-color: #fff;
  color: #EB5757;
}

.viewIcon{
  font-size: 28px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}