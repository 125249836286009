@import 'variables';

.component {

}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: $color-text-5;
}
.avatar {
  height: 80px;
  width: 80px;
  margin-bottom: 24px;
}
.userName {
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: $color-text;
}
.main {
  border-top: 1px solid $color-text-2;
  border-bottom: 1px solid $color-text-2;
}
.button {
  height: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 38px;
  border: 1px solid $color-text;
  border-radius: 8px;
  padding: 0 24px;
  margin: 24px 0;
  color: $color-text;
  &:hover {
    color: $color-text;
  }
}
.main,
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}