@import "variables";

.container {
  width: 740px !important;
  max-width: 100vw;
  grid-column: 2;
  border-radius: 16px;
  :global {
    .ant-modal-content {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 0;
      background-color: transparent;
    }
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
    .ant-select-dropdown {
      display: none !important;
    }
  }
}
.header {
  margin-bottom: 32px;
  text-align: center;
}

.secondTitle {
  font-size: 15px;
  line-height: 17.63px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 24px;
}

.formContainer {
}

.formItem {
  margin: 0 0 8px;
  position: relative;
}

.input {
  padding: 0 20px;
  height: 40px;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;
  &::placeholder {
    color: $color-text-4;
  }
  &:global(.ant-select-focused) {
    border: 2px solid #2f80ed;
    background-color: #fff;
  }
  :global {
    :global(.invalid) {
      color: #eb5757;
    }
  }
}
.footer {
  display: flex;
  flex-direction: column;
}

.actionContainer {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backButton {
  background: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  border: 2px solid $color-text;
  color: $color-text;
  margin-right: 24px;
}

.submitButton {
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
}

.contentCard {
  margin-bottom: 12px;
  padding: 40px;
  border-radius: 10px;
  :global {
    .ant-card-body {
      border-radius: 10px;
    }
  }
}

.successMessage {
  :global {
    .ant-notification-notice-message {
      margin-bottom: 0;
    }
  }
}

.successMessageContainer {
  display: flex;
}

.successIcon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.formLabel {
  margin-left: 8px;
  font-size: 17px;
  font-weight: 400;
  color: $color-text;
}

.closeIcon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  margin: auto;
}

.tagsSelect {
  :global {
    .ant-select-selector {
      padding: 0 15px;
      height: 66px;
      border-radius: 8px;
      color: $color-text;
      background-color: $color-div-0;
    }

    .ant-select-selection-item {
      background: #d5e6fb;
      border-radius: 4px;
      color: #2f80ed;
      height: 36px;
      align-items: center;
    }
  }
}

.tagError {
  text-align: left;
  height: 18px;

  :global {
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #eb5757;
    }
  }
}
