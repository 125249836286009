.root {
	font-size: 15px;
	line-height: 17.63px;
	font-weight: 400;
	padding: 11px 16px;
	border: 1px solid #6c757d;
	border-radius: 8px;
	background-color: transparent;
	margin-right: 11px;
	color: #000;
}
