@import "variables";

.component {
  max-width: 100%;
  background-color: #fff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 24px 24px -8px $box-shadow-color;
  margin-bottom: 48px;
  :global {
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
        font-size: 15px;
        line-height: 19.2px;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
  }
}
.readOnly {
  pointer-events: none;
}
.header {
  position: relative;
  margin-bottom: 32px;
}
.close {
  border: none;
  background: none;
  position: absolute;
  top: -30px;
  right: -30px;
  cursor: pointer;
  height: auto;
  padding: 0;
}
.formItem {
  margin: 0 0 32px;
}
.optionLabel {
  font-size: 17px;
  font-weight: 400;
}
.input {
  padding: 10px 20px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;
  &::placeholder {
    font-size: 17px;
    font-weight: 400;
    line-height: 21.76px;
    font-style: italic;
  }
}
