.component {

}
.deleteButton {
	background: red;
	color: #fff;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
	margin: 0 auto;
	display: block;
	margin-left: 32px;
}
.submitButton {
	margin-left: 10px;
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
	margin: 0 auto;
  display: block;
}