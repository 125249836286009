@import "variables";

.component {
  margin: 0 auto;
  max-width: 992px;
  height: auto;
  padding-bottom: 24px;
}
.info {
  height: 400px;
  width: 676px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 260px;
  grid-template-rows: 120px 120px 120px;
  grid-gap: 16px;
}
.memo {
  grid-row-start: 1;
  grid-row-end: 4;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  border-radius: 16px;
  background-color: #fff;
  padding: 8px;
  .bg {
    height: 232px;
    width: 100%;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.45);
    margin-bottom: 24px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer {
    padding: 16px 16px 0;
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: $color-text;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .hint {
    font-size: 14px;
    line-height: 18px;
    color: $color-text-5;
  }
  .button {
    color: #fff;
    background: #2f80ed;
    border-radius: 8px;
    height: 48px;
    font-size: 17px;
    line-height: 48px;
    padding: 0 50px;
    font-weight: bold;
    display: block;
    // max-width: 205px;
    align-self: center;
    &:hover {
      color: #fff !important;
    }
  }
}
.counter {
  grid-column: 2;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  padding: 24px 32px;
  align-items: center;
  .icon {
    border-radius: 50%;
    margin-right: 32px;
    flex-shrink: 0;
  }
  .text {
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: $color-text-4;
    :global(b) {
      color: $color-text;
      font-size: 40px;
      line-height: 51px;
      display: block;
    }
  }
  &.sentCounter .icon {
    color: #eb5757;
    background-color: rgba(235, 87, 87, 0.2);
  }
  &.openedCounter .icon {
    color: #f2994a;
    background-color: #fcebdb;
  }
  &.completedCounter .icon {
    color: #6fcf97;
    background-color: #e2f5ea;
  }
}
.header {
  margin-bottom: 38px;
  margin-top: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actionHeader {
    display: flex;
    align-items: center;
    .filter {
      margin-right: 24px;
    }
  }
}
.item {
  display: grid;
  padding: 24px;
  height: 96px;
  grid-template-columns: 48px auto 1fr auto auto;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  border-radius: 16px;
  grid-column-gap: 16px;
  max-width: 992px;
  margin-bottom: 24px;
  .date {
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: $color-text-5;
    padding: 0 24px;
  }
  .emailedAt {
    color: $color-text-4;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
  }
}
.avatar {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #eee;
  background-position: center;
  background-size: cover;
}
.name {
  color: $color-text;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
}
.email {
  color: $color-text-4;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
}
.status {
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  .circle {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-right: 8px;
  }
  .retries {
    color: #f2994a;
    margin-left: 128px;
    .retryIcon {
      border-radius: 41px;
      padding: 9px;
      background-color: #fcebdb;
      margin-right: 12px;
    }
  }
  &.completed {
    color: #6fcf97;
    .circle {
      background-color: #6fcf97;
    }
  }
  &.opened {
    color: #f2994a;
    .circle {
      background-color: #f2994a;
    }
  }
  &.sent {
    color: #eb5757;
    .circle {
      background-color: #eb5757;
    }
  }
  &.sending {
    color: #6c757d;
    .circle {
      background-color: #6c757d;
    }
  }
}

.shareButton {
  color: #fff;
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  display: block;
  // max-width: 205px;
  align-self: center;
  margin-top: 24px;
  &:hover,
  &:focus {
    color: #fff !important;
    background: #2f80ed;
  }
}
.remindButton {
  color: #fff;
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  align-self: center;
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    color: #fff !important;
    background: #2f80ed;
  }
  .icon {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }
}

.csvButton {
  color: #fff;
  background: #2f80ed;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  padding: 0 16px;
  font-weight: bold;
  align-self: center;
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    color: #fff !important;
    background: #2f80ed;
  }
}

.remindAllButton {
  color: #fff;
  background: #2f80ed;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  line-height: 21px;
  padding: 0 16px;
  font-weight: bold;
  align-self: center;
  display: flex;
  align-items: center;
  margin-right: 24px;
  &:hover,
  &:focus {
    color: #fff !important;
    background: #2f80ed;
  }

  .icon {
    height: 20px;
    width: 20px;
    margin-right: 16px;
  }
}

.noInvitations {
  color: $color-text-5;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
}

.projectContainer {
  display: flex;
  align-items: center;
}

.project {
  min-width: 71px;
  font-size: 12px;
  font-weight: bold;
  color: $color-text;
  background: $color-text-2;
  border-radius: 4px;
  line-height: 15.36px;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
}

.statsRecipientsList {
  padding: 0;
}