@import 'variables';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  &:empty {
    display: none;
  }
}
.question {
	width: 100%;
	background-color: #fff;
	padding: 40px;
	border-radius: 16px;
	box-shadow: 0px 24px 24px -8px $box-shadow-color;
  margin-bottom: 48px;
  &:last-child {
    margin-bottom: 0;
  }
}
.options {
  display: flex;
  flex-direction: column;
}
.title {
  margin-bottom: 32px;
}
.component {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.option {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .label {
    flex: 1;
    margin-left: 12px;
    margin-bottom: 0;
    color: $color-text-5;
    white-space: initial
  }
  :global {
    .ant-radio-wrapper{
      white-space: initial
    }
    .ant-radio+span {
      padding: 0;
    }
    .ant-radio-inner {
      height: 24px;
      width: 24px;
      border: 1px solid $color-text-5;
    }
    .ant-radio-inner::after {
      height: 16px;
      width: 16px;
      top: 3px;
      left: 3px;
      background-color: #2F80ED;
    }
    .ant-radio-checked::after {
      border-color: #2F80ED;
    }
  }
}
.input {
  border: none;
}

@include phone {
  .question {
    padding: 16px;
    margin-bottom: 24px;
  }
  .title {
    margin-bottom: 24px;
  }
  .formItem {
    margin: 0;
  }
  .option {
    :global {
      .ant-radio-inner {
        height: 16px;
        width: 16px;
        border: 1px solid $color-text-5;
      }
      .ant-radio-inner::after {
        height: 12px;
        width: 12px;
        top: 1px;
        left: 1px;
        background-color: #2F80ED;
      }
    }
    .label {
      font-size: 15px;
      line-height: 19px;
    }
  }
}