@import "variables";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  border-radius: 12px;
  background-color: #f2f2f2;
  :global {
    .ant-steps {
      height: 24px;
      border-radius: 12px;
    }

    .ant-steps-item-content {
      position: absolute;
      top: -40px;
      margin-top: 0;
    }

    .ant-steps-item-content .ant-steps-item-title {
      font-size: 15px;
      font-weight: 700;
    }

    .ant-steps-label-vertical .ant-steps-item-content {
      width: auto;
    }

    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: transparent;
    }

    .ant-steps-dot .ant-steps-item-tail::after,
    .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
      width: calc(100% + 0px);
      height: 24px;
      margin-left: 3px;
    }

    .ant-steps-dot .ant-steps-item-tail,
    .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
      top: 0px;
      margin: 0 0 0 0px;
    }

    .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
      top: 0px;
      left: -6px;
    }

    .ant-steps-item-icon .ant-steps-icon {
      top: 7px;
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: #2f80ed;
      font-weight: 700;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: #2f80ed;
    }

    .ant-steps-item:first-child.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      box-shadow: 0 0.5px 0 5px rgba(47, 128, 237, 0.3);
    }

    .ant-steps div.ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
      width: calc(100% + 17px);
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: rgba(47, 128, 237, 0.2);
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .ant-steps div.ant-steps-item:first-child > .ant-steps-item-container > .ant-steps-item-tail::after {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    .ant-steps-dot .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
      margin-left: 3px;
    }
  }

  &:global(.fourSteps) {
    :global {
      .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-tail::after {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      .ant-steps .ant-steps-item:nth-child(2) > .ant-steps-item-container > .ant-steps-item-content {
        left: -74px;
      }

      .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
        left: -60px;
      }

      .ant-steps .ant-steps-item:nth-child(4) > .ant-steps-item-container > .ant-steps-item-content {
        left: -50px;
      }
    }
  }

  &:global(.fourSteps.french) {
    :global {
      .ant-steps .ant-steps-item:nth-child(2) > .ant-steps-item-container > .ant-steps-item-content {
        left: -88px;
      }

      .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
        left: -76px;
      }

      .ant-steps .ant-steps-item:nth-child(4) > .ant-steps-item-container > .ant-steps-item-content {
        left: -40px;
      }
    }
  }

  &:global(.threeSteps) {
    :global {
      .ant-steps .ant-steps-item:nth-child(2) > .ant-steps-item-container > .ant-steps-item-tail::after {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      .ant-steps .ant-steps-item:nth-child(2) > .ant-steps-item-container > .ant-steps-item-content {
        left: -74px;
      }

      .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
        left: -50px;
      }
    }
  }

  &:global(.threeSteps.french) {
    :global {
      .ant-steps .ant-steps-item:nth-child(2) > .ant-steps-item-container > .ant-steps-item-content {
        left: -80px;
      }

      .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
        left: -40px;
      }
    }
  }
}

@include phone {
  .container {
    width: 200vw;
    margin-left: 50%;
    background-color: transparent;

    :global {
      .ant-steps {
        background-color: #f2f2f2;
        margin-right: calc(50vw - 5px);
      }
    }

    &:global(.fourSteps) {
      :global {
        .ant-steps .ant-steps-item:nth-child(1) > .ant-steps-item-container > .ant-steps-item-content {
          left: -24px;
        }

        .ant-steps .ant-steps-item:nth-child(4) > .ant-steps-item-container > .ant-steps-item-content {
          left: -27px;
        }
      }
    }

    &:global(.fourSteps.french) {
      :global {
        .ant-steps .ant-steps-item:nth-child(1) > .ant-steps-item-container > .ant-steps-item-content {
          left: -28px;
        }

        .ant-steps .ant-steps-item:nth-child(2) > .ant-steps-item-container > .ant-steps-item-content {
          left: -84px;
        }

        .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
          left: -44px;
          top: -54px;
          .ant-steps-item-title {
            max-width: 102px;
            white-space: normal;
          }
        }

        .ant-steps .ant-steps-item:nth-child(4) > .ant-steps-item-container > .ant-steps-item-content {
          left: -20px;
        }
      }
    }

    &:global(.threeSteps) {
      width: 150vw;

      :global {
        .ant-steps .ant-steps-item:nth-child(1) > .ant-steps-item-container > .ant-steps-item-content {
          left: -24px;
        }

        .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
          left: -27px;
        }
      }
    }

    &:global(.threeSteps.french) {
      width: 150vw;

      :global {
        .ant-steps .ant-steps-item:nth-child(1) > .ant-steps-item-container > .ant-steps-item-content {
          left: -28px;
        }

        .ant-steps .ant-steps-item:nth-child(3) > .ant-steps-item-container > .ant-steps-item-content {
          left: -20px;
        }
      }
    }
  }
}
