@import "variables";

.profileBar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 40px;
  vertical-align: center;
  margin-bottom: 16px;
}

.detailsContainer {
  display: flex;
  align-items: center;
}

.avatar {
  color: #fff;
  min-width: 40px;
}

.user {
  font-weight: bold;
  color: $color-text;
}

.team {
  margin-top: 4px;
  font-weight: bold;
  font-size: 14px;
  color: $color-text-4;
}

.projectContainer {
  display: flex;
  align-items: center;
}

.project {
  margin-left: auto;
  min-width: 71px;
  font-size: 12px;
  font-weight: bold;
  color: #f59e0b;
  background: rgba(252, 211, 77, 0.3);
  border-radius: 4px;
  line-height: 15.36px;
  padding: 9px 18px;
  display: flex;
  justify-content: center;
}

.completed {
  color: #10b981;
  background: rgba(110, 231, 183, 0.3);
}

.details {
  display: flex;
  flex-direction: column;
  line-height: 16.64px;
  justify-content: center;
  margin-left: 8px;
}
