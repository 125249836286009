@import "variables";

.container {
  display: grid;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  padding: 0 16px;
  overflow: auto;
  margin-bottom: 48px;
}
.component {
  margin-top: 48px;
  width: 100%;
  max-width: 740px;
  grid-column: 2;
  justify-self: center;
  align-items: center;
}
.card {
  padding: 20px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  background: $whitecolor;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  margin: 22px 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: $color-text;
}
.domain {
  color: #2f80ed;
}
.form {
  margin: 0 auto;
  :global {
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
  }
}
.formItem {
  margin-bottom: 40px;
  &:first-child {
    margin-right: 16px;
  }
}
.input {
  padding: 0 20px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: #fff;
  &::placeholder {
    color: $color-text-4;
  }
}
.submitButton {
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  color: #fff;
  margin: 0 auto;
  display: block;
}
.dummyPlayer {
  .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
    max-width: 290px;
    color: $whitecolor;
    margin-bottom: 10px;
  }
  .hint {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: $whitecolor;
    margin-bottom: 10px;
    text-align: center;
    max-width: 350px;
    :global {
      b {
        color: $whitecolor;
      }
    }
  }
}

.loading {
  width: 100%;
  max-width: 740px;
  grid-column: 2;
  justify-self: center;
  align-items: center;
}

@include phone {
  .container {
    margin-bottom: 24px;
  }
  .component {
    margin-top: 24px;
  }
  .titleContainer {
    justify-content: flex-end;
  }
  .title {
    margin: 16px 0;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: $color-text;
    padding: 0;
  }
  .flexColumn {
    align-items: flex-start;
  }
  .main {
    margin-bottom: 24px;
    height: 187px;
  }
  .form {
    text-align: center;
    flex-direction: column;
    :global {
      .ant-form-item-label {
        margin-bottom: 0;
      }
    }
  }
  .formItem {
    margin-bottom: 24px;
    &:first-child {
      margin-right: 0;
    }
  }
  .submitButton {
    width: 100%;
    text-align: center;
    display: block;
  }
}
