@import "variables";

.container {
  width: 920px !important;
  max-width: 100vw;
  grid-column: 2;
  border-radius: 16px;
  :global {
    .ant-modal-content {
      max-height: 590px;
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 0;
      background-color: transparent;
    }
  }
}

.header {
  margin-bottom: 32px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  border: 2px solid $color-text;
  color: $color-text;
  margin-right: 16px;
}

.contentCard {
  margin-bottom: 12px;
  padding: 40px;
  border-radius: 10px;
  :global {
    .ant-card-body {
      border-radius: 10px;
    }
  }
}

.closeIcon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  margin: auto;
}

.modalTitle {
  display: flex;
  align-items: center;
}

.backButton {
  border: unset;
  box-shadow: unset;
  margin-right: 16px;
}

.search {
  width: calc(100% - 10px);
  margin-bottom: 25px;
  min-width: 280px;

  :global {
    .ant-select-selector {
      border: unset !important;
      border-radius: 8px !important;
      height: 40px !important;
    }

    .ant-select-selection-search {
      height: 40px;
    }

    .ant-select-selection-placeholder {
      line-height: 40px !important;
    }

    .ant-select-selection-search-input {
      border-radius: 8px;
      height: 100%;
    }

    .ant-input-prefix {
      margin-right: 12px;
    }

    input {
      padding: 0 20px;
      border: none;
      border-radius: 8px;
      color: $color-text;
      background-color: #fff;
      min-width: 280px;
      width: auto;
      &::placeholder {
        color: $color-text-5;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.input {
  :global {
    input {
      padding: 0 20px;
      height: 40px !important;
      border: none;
      border-radius: 8px;
      color: $color-text;
      background-color: #fff;
      min-width: 480px;
      width: auto;
      &::placeholder {
        color: $color-text-4;
      }
    }
  }
}

.dropdown {
  padding: 16px;
  border-radius: 8px;
  :global {
    .ant-select-item {
      min-height: 50px;
      padding: 16px;
      font-size: 15px;
      line-height: 17.63px;
    }
  }
}

.confirmModal {
  width: 440px !important;
  max-width: 100vw;
  grid-column: 2;
  border-radius: 16px;
  :global {
    .ant-modal-content {
      border-radius: 16px;
      .ant-modal-confirm-body {
        text-align: center;
        .ant-modal-confirm-title {
          font-size: 28px;
          line-height: 32.9px;
          font-weight: 700;
          margin-bottom: 18px;
        }
        .ant-modal-confirm-content {
          font-size: 15px;
          line-height: 17.63px;
          font-weight: 400;
          color: #6c757d;
        }
      }
      .ant-modal-confirm-btns {
        margin-top: 24px;
        float: unset;
        text-align: center;

        .ant-btn {
          border-radius: 8px;
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          padding: 15px 30px;
          height: 48px;
          background-color: #2f80ed;
          color: #fff;
          &:hover {
            color: #40a9ff;
            background: #fff;
            border-color: #40a9ff;
          }
        }

        button + button {
          margin-bottom: 0;
          margin-left: 24px;
        }
      }
    }
    .ant-modal-body {
      border-radius: 16px;
    }
  }
}

.confirmTitle {
  text-align: center;
  padding: 2px 0;
}

.confirmDescription {
  color: #15141a;
}

.tableWrapper {
  height: calc(590px - 280px);
  width: calc(100% - 2px);
  :global {
    .ant-table-wrapper {
      height: 100%;

      .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table {
            flex: auto;
            overflow-y: scroll;

            .ant-table-container {
              display: flex;
              flex-flow: column nowrap;

              .ant-table-header {
                flex: none;

                .ant-table-thead > tr > th {
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 17.63px;
                  color: #6c757d;
                }
              }

              .ant-table-body {
                flex: auto;
              }
            }
          }

          .ant-table-pagination {
            flex: none;
          }
        }
      }
    }
  }
}

.tag {
  padding: 8px 16px;
  font-size: 12px;
  line-height: 14.1px;
  color: #15141a;
  font-weight: 700;
  border-radius: 8px;

  &.selected {
    color: #fff;
    background: #2f80ed;
  }
}

.tableContainer {
  // padding-bottom: 72px;
  :global {
    .ant-table {
      background-color: transparent;
      color: #6c757d;
      .ant-table-thead > tr > th {
        background-color: #e6e6e6;
      }

      .ant-table-thead > tr > th:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .ant-table-thead > tr > th:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .ant-table-tbody > tr > td:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      .ant-table-tbody > tr > td:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .ant-table-tbody > tr.ant-table-row-selected > td {
        background-color: #cbdffa;
        color: #2f80ed;

        .ant-btn-link {
          color: #2f80ed;
        }
      }

      .ant-checkbox-wrapper .ant-checkbox-inner,
      .ant-checkbox .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #ddd;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
      }

      .ant-checkbox-checked::after {
        border: unset;
      }
    }
  }
}

.statusIcon {
  color: $color-nav-text-active;
  width: 16px;
  height: 16px;
  font-size: 16px;
  cursor: pointer;
}

.footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedCount {
  color: $color-nav-text-active;
}

.selectedContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.selectedTag {
  margin-top: 16px;
}

.restTagsContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.selectedLabel {
  color: #6c757d;
}

.moreLabel {
  margin-right: 16px;
}

.updateButton {
  color: #2f80ed;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.successMessage {
  :global {
    .ant-notification-notice-message {
      margin-bottom: 0;
    }
  }
}

.successMessageContainer {
  display: flex;
}

.successIcon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.checkSkipping {
  margin-top: 32px;
  span {
    font-size: 15px;
    line-height: 17.63px;
    font-weight: 400;
    color: #000;
  }
}
