@import 'variables';

.component {
  width: 100%;
  color: #000;
  text-align: center;
  &:global(.failed) {
    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
    }
  }
  :global {
    h3 {
      color: #000;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    p {
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 24px;
    }
    span {
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
    }
  }
}


@include phone {
	.component {
    padding: 16px;
    :global {
      h3 {
        font-size: 28px;
        line-height: 22px;
        margin-bottom: 24px;
      }
      p {
        font-size: 15px;
        line-height: 19px;
      }
      span {
        font-size: 15px;
        line-height: 19px;
      }
    }
	}
}