$desktop-min: 1366px;
$box-shadow-color: rgba(45, 46, 64, 0.1);
$box-shadow-dark-color: rgba(45, 46, 64, 0.4);
$whitecolor: #fff;
$color-text: #2d2e40;
$color-text-5: #6c757d;
$color-text-4: #adb5bd;
$color-text-2: #dee2e6;
$color-div-0: #f3f5f7;
$color-div-1: #f5f7f9;
$color-div-3: #f8f8f8;
$color-nav-text: $color-text-5;
$color-nav-text-active: #2f80ed;

@mixin phone {
  @media (max-width: 639.98px) {
    @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 640px) and (max-width: 1365.98px) {
    @content;
  }
}

@mixin touch {
  @media (max-width: 1365.98px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1366px) {
    @content;
  }
}
