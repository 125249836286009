.root {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 56px;
  background-color: #2F80ED;
  color: white;
  display: flex;
  align-items:center;
  justify-content: space-between;
  width: 100%;
  padding: 19.5px 8px;
  z-index: 2;
}

.closeButton {
  background: #4E93F0;
	border-radius: 8px;
	height: 40px;
	font-size: 14px;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
	color: #fff;
	align-self: center;
  width: auto;
  border: unset;
}