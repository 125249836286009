@import "variables";

.component {
  background-color: $color-div-3;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 72px 1fr;
  &.withStepper {
    grid-template-rows: 176px 1fr;
  }
}
.content {
  display: grid;
  grid-template-rows: 1fr;
  overflow: auto;
}

@include touch {
  .component {
    // padding-top: 0;
  }
  .subHeader {
    box-sizing: content-box;
  }
}
