@import "variables";

.component {
  display: flex;
  flex-direction: column;
  justify-self: stretch;
}

.items {
  width: 100%;
}
.question {
  max-width: 100%;
  background-color: #fff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 24px 24px -8px $box-shadow-color;
  margin-bottom: 48px;
  :global {
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        color: $color-text-5;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
  }
  .header {
    position: relative;
  }
  .close {
    border: none;
    background: none;
    position: absolute;
    top: -30px;
    right: -30px;
    cursor: pointer;
    height: auto;
    padding: 0;
  }
}
.button {
  border-radius: 8px;
  background: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  margin: 0 auto;
  display: block;
  border: unset;
}
