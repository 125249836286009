.component {
  width: 112px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  margin: 32px 0 16px;
  border: 1px solid #eee;
  :global(.ant-upload) {
    border: none;
    height: auto;
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0 !important;
  }
}


.content{
  display: flex;
  align-items: center;
}