@import 'variables';

.component {
  padding: 48px;
	display: grid;
	align-items: flex-start;
	grid-template-columns: 1fr minmax(auto, 1112px) 1fr;
}
.content {
	grid-column: 2;
}
.button {
	color: #fff;
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 50px;
	font-weight: bold;
	display: block;
	&:hover {
		color: #fff !important;
	}
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}