.container {
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-weight: 900;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #2F80ED;
  white-space: pre-wrap;
  margin: 40px 10px;
}

.subtitle {
  color: #15141A;
  font-weight: 700;
}

.image {
  text-align: center;
  img {
    max-width: 90%;
  }
}

.text {
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  color: #15141A;
  text-align: center;
  white-space: pre-wrap;
  margin: 0 10px;
}