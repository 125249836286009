@import 'variables';

.container {
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: auto 600px auto;
	padding: 0 10px;	
}
.component {
	max-width: 100%;
	grid-column: 2;
	background-color: #fff;
	padding: 36px;
	border-radius: 16px;
	box-shadow: 0px 24px 24px -8px $box-shadow-color;
	:global {
		.ant-form-item-label {
			text-align: left;
			font-weight: bold;
			font-size: 15px;
			line-height: 19px;
			margin-bottom: 8px;
			label {
				height: auto;
				color: $color-text-5;
			}
		}
		.ant-form-item-required::before,
		.ant-form-item-required::after {
			display: none !important;
		}
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 24px;
}
.title {
	font-weight: bold;
	font-size: 40px;
	line-height: 51px;
	margin-bottom: 16px;
	color: $color-text;
}
.desc {
	font-size: 17px;
	line-height: 22px;
	width: 290px;
	color: $color-text-5;
}
.alert {
	padding: 16px !important;
	margin-bottom: 24px;
	text-align: center;
	background: rgba(235, 87, 87, 0.2);
	border-radius: 8px;
	:global {
		.ant-alert-message {
			color: #EB5757;
			font-weight: 500;
			font-size: 15px;
			line-height: 19px;
		}
	}
}
.formItem {
	margin: 0 0 24px;
	position: relative;
}
.spyIcon {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
	line-height: 24px;
	color: $color-text-5;
	cursor: pointer;
}
.input {
	padding: 0 20px;
	height: 40px;
	border: none;
	border-radius: 8px;
	color: $color-text;
	background-color: $color-div-0;
	&::placeholder {
		color: $color-text-4;
	}
}
.fpc {
	text-align: right;
	height: 18px;
	:global {
		a {
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			color: $color-text;
			text-decoration: underline;
		}
	}
}
.submitButton {
	margin-left: 10px;
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 50px;
	font-weight: bold;
	margin: 0 auto;
  display: block;
}