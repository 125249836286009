@import "variables";
.title {
  font-weight: bold;
  margin: 0;
  color: $color-text;
  &.h1 {
    font-size: 40px;
    line-height: 47px;
    font-weight: 700;
  }
  &.h2 {
    font-size: 28px;
    line-height: 32.9px;
    font-weight: 700;
  }
  &.h3 {
    font-size: 21px;
    line-height: 24.68px;
    font-weight: 700;
  }
  &.h4 {
    font-size: 15px;
    line-height: 17.63px;
    font-weight: 700;
  }
  &.h5 {
    font-size: 12px;
    line-height: 14.1px;
    font-weight: 700;
  }
  &.h5 {
    font-size: 12px;
    line-height: 14.1px;
    font-weight: 700;
  }
  &.medium {
    font-weight: 500;
  }
  &.regular {
    font-weight: 400;
  }
  &.underline {
    font-weight: 400;
    text-decoration: underline;
  }
}

@include phone {
  .title {
  }
}
