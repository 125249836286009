@import "variables";

.component {
  height: 40px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;

  &::placeholder {
    color: $color-text-4;
  }
  :global {
    .ant-select-selection-item {
      padding: 0 20px !important;
    }
    .ant-select-selector {
      padding: 0 !important;
    }
    .ant-select-selector,
    .ant-select-selection-item {
      background: transparent !important;
      border: none !important;
      height: 100% !important;
      line-height: 40px !important;
      display: flex;
      align-items: center;
    }
  }
}

.readOnly {
  pointer-events: none;
}

.downdownIcon {
  font-size: 12px;
}

.icon {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  font-size: 18px;
  // color: #000;
}
