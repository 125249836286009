@import "variables";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 16px;
  overflow: hidden;
  margin-top: 48px;
  &:global(.completed) {
    .questions {
      pointer-events: none;
    }
  }

  &:global(.showStepper) {
    margin-top: 152px;
  }
}

.centered {
  justify-self: center;
  width: 100%;
  max-width: 740px;
  grid-column: 2;
  display: flex;
  align-items: center;
}

.component {
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 12px;
  box-shadow: 0px 24px 24px -8px $box-shadow-color;
  display: block;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  margin: 22px 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: $color-text;
}
.completed {
  font-size: 17px;
  line-height: 22px;
  color: $color-text-5;
  margin-bottom: 16px;
  display: block;
}
.desc {
  font-size: 17px;
  line-height: 22px;
  color: $color-text;
  margin: 0;
}
.footer {
  width: 100%;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}
.startButton {
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  color: #fff;
  align-self: center;
  width: auto;
  margin-top: 48px;
}
.primaryButton {
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  color: #fff;
  align-self: center;
  width: auto;
  margin-left: 8px;
  margin-right: 8px;
}
.dummyPlayer {
  .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
    max-width: 290px;
    color: $whitecolor;
    margin-bottom: 10px;
  }
  .hint {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: $whitecolor;
    margin-bottom: 10px;
    text-align: center;
    max-width: 350px;
    :global {
      b {
        color: $whitecolor;
      }
    }
  }
}
.successMessage {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 16px;
  background-color: #e2f5ea;
  color: #6fcf97;
  border-radius: 8px;
}
.modal {
  :global {
    .ant-modal-content {
      border-radius: 16px;
    }
    .ant-modal-body {
      padding: 40px;
    }
  }
}

.animationContainer {
  position: relative;
  animation-name: example;
  animation-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@keyframes example {
  0% {
    left: 0px;
    top: 372px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

.disappearAnimation {
  position: relative;
  animation-name: disappear;
  animation-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes disappear {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: -372px;
  }
}

.questionNote {
  font-size: 21px;
  line-height: 26.88px;
  font-weight: 700;
  margin-top: 51px;
  margin-bottom: 51px;
}

.questions {
  padding-top: 8px;
}

@include phone {
  .container {
    margin-top: 24px;
    margin-bottom: 24px;
    &:global(.showStepper) {
      margin-top: 104px;
    }
  }
  .component {
    padding: 16px;
  }
  .flexColumn {
    align-items: flex-start;
  }
  .startButton {
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 24px;
  }
  .embed {
    border-radius: 0;
  }
  .title {
    margin: 16px 0;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    color: $color-text;
    padding: 0;
  }
  .completed {
    padding: 0 16px;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    color: $color-text-4;
  }
  .titleContainer {
    width: 100%;
    grid-column: 2;
  }
  .footer {
    margin-bottom: 16px;
  }

  .questionNote {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 19.2px;
    font-weight: 400;
  }
}
