@import "variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  overflow: auto;
  height: 100%;
  @include phone {
    margin-top: 124px;
    padding: 20px;
  }
}
.component {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 16px 32px rgba(45, 46, 64, 0.1);
}

.card {
  width: 100%;
  padding: 40px;
  background: $whitecolor;
}

.title {
  text-align: center;
  margin-bottom: 32px;
}

.hint {
  font-weight: normal;
  font-size: 15px;
  line-height: 17.63px;
  text-align: center;
  color: $color-text-5;
  margin-bottom: 0;
  :global {
    b {
      color: $whitecolor;
    }
  }
}