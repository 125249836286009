@import "variables";

.component {
  height: 312px;
  width: 320px;
  background-color: #fff;
  box-shadow: 0px 24px 24px -8px rgba(45, 46, 64, 0.1);
  border-radius: 16px;
  padding: 8px;
  position: relative;
}
.bg {
  height: 172px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
  background-position: center;
  background-size: cover;
}
.header {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions{
    display: flex;
    align-items: center;
  }
}
.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 0;
  margin-right: 8px;
  color: #fff;
  &:hover,
  &:focus {
    color: $whitecolor;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.stats {
  line-height: 32px;
  text-align: center;
  color: $whitecolor;
}
.memoId {
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  font-weight: bold;
  font-size: 13px;
  justify-self: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  display: block;
  color: $whitecolor;
}
.avatar {
  position: absolute;
  top: 160px;
  left: calc(50% - 20px);
  height: 40px;
  width: 40px;
}
.info {
  padding: 16px 16px 0;
}
.title {
  font-weight: bold;
  font-size: 21px;
  line-height: 26.88px;
  color: $color-text;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 53.33px;
}
.hint {
  font-size: 14px;
  line-height: 18px;
  color: $color-text-5;
}

@media (max-width: 1400px) {
  .component {
    width: 270px;
  }
}

@media (max-width: 960px) {
  .component {
    width: 320px;
  }
}