@import "variables";

.root {
  height: auto;
  padding-bottom: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  height: 51px;
}
