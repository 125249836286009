@import 'variables';

.root {
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: auto;
}

@include phone {
  .root {
    max-width: 300px;
  }
}
