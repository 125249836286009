@import "variables";

.main {
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  height: 372px;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  border-radius: 8px;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // padding: 0 40px;
  text-align: center;
}
.overlay {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.mainContainer {
  z-index: 1;
  color: $whitecolor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@include phone {
  .main {
    margin-bottom: 24px;
    height: 187px;
  }
}
