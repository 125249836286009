@import 'variables';

.container {
  text-align: center;
  display: flex;
  justify-content: center;
}
.component {
  display: inline-block;
  height: 72px;
  width: auto;
  list-style-type: none;
  margin: 24px 0 0;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 24px $box-shadow-color;
  display: flex;
  align-items: center;
}
.link {
  padding: 0 24px;
  height: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 8px;
  margin-right: 32px;
  color: $color-nav-text;
  &:last-child {
    margin-right: 0;
  }
  &:global(.active) {
    color: $color-nav-text-active;
    background: rgba(47, 128, 237, 0.2);
  }
}
.newMemoButton {
  background: #2F80ED;
  color: #fff;
  &:hover {
    color: #fff;
  }
}