.component {
	height: 28px;
	width: 28px;
	line-height: 28px;
	font-size: 28px;
	background-color: transparent;
	font-family: icomoon;
	text-align: center;
	vertical-align: middle;
	display: inline-block;
}
.animated {
	transition-property: transform, box-shadow, color, border-color;
	transition-duration: 0.3s;
	&.shadow:hover {
		box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
	}
	&:hover {
		// transform: scale(1.1);
	}
}
.transparent {
	border-radius: 0;
	background-color: transparent;
}
.small {
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 16px;
}
.large {
	width: 64px;
	height: 64px;
	line-height: 64px;
	font-size: 32px;
}

// general icons
.share::before {
	content: "\e906";
}
.edit::before {
	content: "\e900";
}
.stats::before {
	content: "\e907";
}
.eye::before {
	content: "\e90f";
}
// @TODO use font
.refresh {
	background: url('../icons/StatsProgress.png') no-repeat;
	background-size: 28px;
	background-position: center;
	vertical-align: top;
}
// @TODO use font
.complete {
	background: url('../icons/StatsCompleted.png') no-repeat;
	background-size: 28px;
	background-position: center;
	vertical-align: top;
}
.image::before {
	content: "\e903";
}
// @TODO use font
.delete {
	background: url('../icons/delete.png') no-repeat;
	background-size: 28px;
	background-position: center;
	vertical-align: top;
	height: 100%;
}
.close {
	background: url('../icons/close.png') no-repeat;
	background-size: 32px;
	background-position: center;
	vertical-align: top;
	height: 32px;
	width: 32px;
}
.allProjects::before {
	content: "\e90a";
}
.defaultProject::before {
	content: "\e90b";
}
.project::before {
	content: "\e90c";
}
.notify {
	background: url('../icons/notify.png') no-repeat;
	background-size: 100%;
	background-position: center;
	vertical-align: top;
	height: 32px;
	width: 32px;	
}
