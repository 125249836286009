@import 'variables';

.container {
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: auto 600px auto;
	padding: 0 10px;	
}
.component {
	max-width: 100%;
	grid-column: 2;
	background-color: #fff;
	padding: 36px;
	border-radius: 16px;
	box-shadow: 0px 24px 24px -8px $box-shadow-color;
	:global {
		.ant-form-item-label {
			text-align: left;
			font-weight: bold;
			font-size: 15px;
			line-height: 19px;
			margin-bottom: 8px;
			label {
				height: auto;
				color: $color-text-5;
			}
		}
		.ant-form-item-required::before,
		.ant-form-item-required::after {
			display: none !important;
		}
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: 32px;
}
.title {
	font-weight: bold;
	font-size: 28px;
	line-height: 36px;
	margin-bottom: 32px;
	color: $color-text;
}
.desc {
	font-size: 17px;
	line-height: 22px;
	color: $color-text-5;
}
.icon {
	height: 80px;
	width: 80px;
	margin-bottom: 32px;
}
.alert {
	margin-bottom: 32px;
}
.formItem {
	margin: 0 0 32px;
	position: relative;
}
.input {
	padding: 0 20px;
	height: 40px;
	border: none;
	border-radius: 8px;
	color: $color-text;
	background-color: $color-div-0;
	&::placeholder {
		color: $color-text-4;
	}
}
.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 290px;
	width: 100%;
	margin: 0 auto;
}
.backButton {
	background: #fff;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
	border: 2px solid $color-text;
	color: $color-text;
}
.submitButton {
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
}