.component {
  
}

.progressContainer{
  :global {
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
		}
		.ant-modal-body {
      background: transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
		}
	}
}