@import "variables";

.container {
  margin-top: 48px;
  display: grid;
  justify-content: center;
  grid-template-columns: auto 1fr auto;
  overflow: auto;
  padding: 24px;
  &:global(.showStepper) {
    margin-top: 152px;
    @include phone {
      margin-top: 124px;
      height: calc(100% - 124px);
    }
  }
}
.component {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  grid-column: 2;
  justify-self: center;
  overflow: hidden;
  background-color: white;
}

.card {
  padding: 24px 16px;
  border-radius: 8px;
  box-shadow: 0px 24px 32px rgba(45, 46, 64, 0.1);
  background: $whitecolor;
}

.appearAnimation {
  position: relative;
  animation-name: appear;
  animation-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disappearAnimation {
  position: relative;
  animation-name: disappear;
  animation-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes appear {
  0% {
    left: 0px;
    top: 372px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes mobileAppear {
  0% {
    top: 0px;
    left: 100vw;
  }
  100% {
    top: 0px;
    left: 0px;
  }
}

@keyframes disappear {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: -372px;
  }
}

@keyframes mobileDisappear {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: 100vw;
    top: 0;
  }
}

.title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
  max-width: 400px;
  color: #15141a;
  margin-bottom: 10px;
}

.hint {
  font-weight: normal;
  font-size: 15px;
  line-height: 22.5px;
  color: #4f4f4f;
  margin-bottom: 10px;
  text-align: center;
  max-width: 350px;
  :global {
    b {
      color: #2f80ed;
    }
  }
}

.domain {
  color: #2f80ed;
}
.main {
}
.player {
  width: 100%;
  height: 100%;
}
.playerCover {
  height: 100%;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  :global {
    .ant-form-item-label {
      text-align: left;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 8px;
      label {
        height: auto;
        // color: $whitecolor;
      }
    }
    .ant-form-item-required::before,
    .ant-form-item-required::after {
      display: none !important;
    }
  }
}
.formItem {
  width: 100%;
  margin: auto;
  margin-bottom: 12px;
  @include phone {
    max-width: 352px;
  }
  label {
    color: #828282;
  }
}
.input {
  padding: 0 20px;
  height: 40px;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: #f8f8f8;
  font-size: 16px;
  &::placeholder {
    color: $color-text-4;
  }
}
.submitButton {
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  color: #fff;
  margin: 0 auto;
  display: block;
  margin-top: 12px;
  @include phone {
    margin-top: auto;
  }
}

.showTutorialButton {
  margin-top: 16px;
  color: #000;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
}

.questionNote {
  font-size: 21px;
  line-height: 26.88px;
  font-weight: 700;
  margin-top: 51px;
  margin-bottom: 51px;
}

@include phone {
  .container {
    margin-top: 0;
    background-color: white;
  }
  .component {
    padding: 0;
    border-radius: unset;
    border: unset;
  }
  .title {
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }
  .main {
  }
  .hint {
    font-size: 15px;
    line-height: 22.5px;
    margin-bottom: 5px;
    text-align: center;
    color: $color-text;
    margin-bottom: 16px;
    max-width: unset;
    :global {
      b {
        color: $color-text;
      }
    }
  }
  .form {
    width: 100%;
    flex-direction: column;
    padding: 0;
    :global {
      .ant-form-item-label {
        margin-bottom: 0;
      }
    }
  }
  .formItem {
    margin-bottom: 24px;
    width: 100%;
    max-width: unset;
  }

  .appearAnimation {
    position: relative;
    animation-name: mobileAppear;
    animation-duration: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;
  }

  .disappearAnimation {
    position: relative;
    animation-name: mobileDisappear;
    animation-duration: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .questionNote {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 19.2px;
    font-weight: 400;
  }
}
