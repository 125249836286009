@import 'variables';

.countdown {
	:global {
    .ant-progress-circle {
      .ant-progress-text {
        font-size: 3rem;
        font-weight: 800;
      }
      &.ant-progress-status-success {
        .ant-progress-text {
          color: #1A202C
        }
      }
    }
	}
}