.component {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.option {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  :global(.ant-radio+span) {
    flex: 1;
  }
  :global {
    .ant-radio-inner {
      height: 24px;
      width: 24px;
    }
    .ant-radio-inner::after {
      height: 16px;
      width: 16px;
      top: 3px;
      left: 3px;
      background-color: #2F80ED;
    }
    .ant-radio-checked::after {
      border-color: #2F80ED;
    }
  }
}
.input {
  border: none;
}