@import "variables";

.container {
  width: 920px !important;
  max-height: 590px;
  max-width: 100vw;
  grid-column: 2;
  border-radius: 16px;
  :global {
    .ant-modal-content {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 0;
      background-color: transparent;
    }
  }
}

.header {
  margin-bottom: 32px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  background: #fff;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 48px;
  padding: 0 24px;
  font-weight: bold;
  border: 2px solid $color-text;
  color: $color-text;
  margin-right: 16px;
}

.contentCard {
  margin-bottom: 12px;
  padding: 40px;
  border-radius: 10px;
  :global {
    .ant-card-body {
      border-radius: 10px;
    }
  }
}

.closeIcon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  margin: auto;
}

.modalTitle {
  display: flex;
  align-items: center;
}

.backButton {
  border: unset;
  box-shadow: unset;
  margin-right: 16px;
}

.input {
  :global {
    input {
      padding: 0 20px;
      height: 40px !important;
      border: none;
      border-radius: 8px;
      color: $color-text;
      background-color: #fff;
      min-width: 480px;
      width: auto;
      &::placeholder {
        color: $color-text-4;
      }
    }
  }
}


.linkContainer {
  width: 100%;
  display: flex;
  input {
    height: 48px;
  }
  .copyButton {
    background: #2f80ed;
    border-radius: 8px;
    height: 48px;
    font-size: 17px;
    line-height: 48px;
    padding: 0 24px;
    font-weight: bold;
    margin-left: 16px;
  }
}

.link_url {
  margin-bottom: 15px;
  height: auto;
  border: none;
  border-radius: 8px;
  color: $color-text;
  background-color: $color-div-0;
  border: 2px solid transparent;
  &::placeholder {
    color: $color-text-4;
  }
  &:global(.ant-select-focused) {
    border: 2px solid #2f80ed;
    background-color: #fff;
  }
  :global {
    .ant-select-selector {
      border: none;
      padding: 16px;
      background-color: transparent;
    }
    .ant-tag {
      padding: 0 8px;
      background: #d5e6fb;
      color: #2f80ed;
      font-size: 15px;
      height: 36px;
      line-height: 36px;
      border-radius: 4px;
      border: none;
    }
  }
}
