.component {
  color: #fff;
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 50px;
	font-weight: bold;
	&:hover {
    color: #fff;
	}
}