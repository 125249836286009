@import "variables";

.mainContainer {
  user-select: none;
  width: inherit;
  height: inherit;
}
.mainContainer:fullscreen,
.mainContainer:full-screen,
.mainContainer:-webkit-full-screen,
.mainContainer:-webkit-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
}

.videoModal {
  &:global(.ant-modal) {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }
  :global {
    .ant-modal,
    .ant-modal-content {
      height: 100vh;
      width: 100vw;
      margin: 0;
      top: 0;
    }
    .ant-modal-body {
      height: calc(100vh - 110px);
    }
  }
}

.playerContainer {
  position: relative;
  user-select: none;
  width: inherit;
  height: inherit;
}

.player {
  width: 100%;
  height: 100%;
  -moz-box-shadow: inset 0 -40px 40px -40px black;
  -webkit-box-shadow: inset 0 -40px 40px -40px black;
  box-shadow: inset 0 -40px 40px -40px black;
}

.playPauseButton {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  &::before {
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    bottom: 6px;
    left: 10px;
    height: 30px;
    width: 30px;
    font-family: icomoon;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    color: $whitecolor;
  }
}
.play::before {
  content: "\ea1c";
}
.stop::before {
  content: "\ea1d";
}

.fullscreenButton {
  &::before {
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    font-family: icomoon;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    color: $whitecolor;
  }
}
.enterFullscreen::before {
  content: "\e902";
}
.exitFullscreen::before {
  content: "\e901";
}
.controlsContainer {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 12px;
  left: 50px;
  right: 200px;
  border-radius: 3px;
  text-shadow: 0px 0px 3px #000;
  color: #fff;
  & > *:first-child {
    margin-right: 10px;
  }
  &.fullWidth {
    right: 50px;
  }
}

.hide {
  opacity: 0;
}

.loading {
  opacity: 0;
}

.fullHeight {
  height: 100%;
}

.closeIcon {
  margin-left: 24px;
}

.popover {
  z-index: 999;
}

.popoverContainer {
  display: flex;
  align-items: center;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.volumeContainer {
  box-sizing: border-box;
  position: absolute;
  right: 50px;
  bottom: 12px;
  width: 140px;
  height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.timelineContainer {
  z-index: 2;
  position: absolute;
  bottom: 32px;
  left: 14px;
  width: calc(100% - 28px);
  :global {
    .ant-slider-rail {
      background: #fff;
      opacity: 0.5;
    }
  }
}

.dummnyTimeline {
  z-index: 1;
  position: absolute;
  bottom: 42px;
  left: 14px;
  width: calc(100% - 28px);
  margin-top: 10px;
  margin-bottom: 10px;
  height: 12px;
  :global {
    .ant-slider-step {
      background: #fff;
      opacity: 0.5;
    }
  }
}

.bottomBarShadow {
  width: 100%;
  bottom: 0;
  position: absolute;
  height: 60px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
}
