@import 'variables';

.container{
	margin-top: 0;
}
.centered {
	justify-self: center;
	width: 100%;
	max-width: 740px;
	grid-column: 2;
	display: flex;
	align-items: center;
}
.flexColumn{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.title {
	margin: 22px 0;
	font-weight: bold;
	font-size: 28px;
	line-height: 36px;
	color: $whitecolor;
}
.hint{
	font-size: 17px;
	line-height: 21.76px;
	color: $whitecolor;
}
.startButton {
	background: #2F80ED;
	border-radius: 8px;
	height: 48px;
	font-size: 17px;
	line-height: 48px;
	padding: 0 24px;
	font-weight: bold;
	color: #fff;
	align-self: center;
	width: auto;
  margin-bottom: 48px;
}

@include phone {
	.container{
		margin-top: 48px;
	}
	.flexColumn{
		align-items: flex-start;
	}
	.startButton{
		width: 100%;
		text-align: center;
		display: block;
	}
	.title {
		margin: 16px 0;
		font-weight: bold;
		font-size: 17px;
		line-height: 22px;
		color: $color-text;
		padding: 0;
	}
	.hint{
		color: $color-text;
	}
	.centered {
		margin-top: 24px;
	}
}
