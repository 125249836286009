@import "variables";

.memo_footer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
}

.collapseButton {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: 44px;
  width: 44px;
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}

.collapsedDate {
  margin-top: 8px;
  color: $color-text-4;
  font-size: 15px;
  line-height: 19px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0;
}

.name {
  font-size: 28px;
  line-height: 30px;
  font-weight: bold;
  margin-right: 44px;
}

.details {
  color: #828282;
  font-size: 15px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 4px;
  margin-right: 44px;
  width: 100%;
  :global {
    span {
      white-space: normal;
      margin-left: 8px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.collapsed {
  overflow-y: hidden;

  .description,
  .details {
    display: none;
  }
}

.attachmentsBlock {
  margin-top: 24px;
}

.attachmentsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 4px;
}