.attachment {
  padding: 8px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 256px;
}

.attachmentDisplay {
  width: 45%;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
  }
}

.deleteAttachmentButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.attachmentBody {
  width: 100%;
  > div {
    display: flex;
  }
}

.attachmentInfo {
  font-weight: 500;
  color: #15141A;
  margin: 0 8px;
  flex-grow: 1;
}

.attachmentName {
  font-size: 14px;
  margin: 0;
  text-align: left;
}

.attachmentSize {
  font-size: 10px;
  margin: 0;
  text-align: left;
}

.progressBar {
  width: 100%;
  height: 4px;
  background: #F2F2F2;
  border-radius: 4px;
  margin-top: 4px;
}

.filledProgressBar {
  height: 4px;
  background: #15141A;
  border-radius: 4px;
}
