@import "variables";

.component {
  width: 280px;
  background-color: #fff;
  display: grid;
  grid-template-rows: 156px 1fr 100px;
  flex-shrink: 0;
}
.header {
}
.appLogo {
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teamName {
  height: 40px;
  border-radius: 8px;
  background: $color-div-0;
  margin: 0 24px 32px;
  padding: 0 4px;
  font-weight: bold;
  font-size: 15px;
  color: $color-text;
  display: flex;
  align-items: center;
  position: relative;
}
.teamLogo {
  margin-right: 8px;
}
.main {
  border-top: 1px solid $color-text-2;
  border-bottom: 1px solid $color-text-2;
  overflow: auto;
  display: grid;
  flex-direction: column;
  grid-template-rows: 1fr 58px;
  flex-shrink: 0;

  .projectsContainer {
    flex-grow: 1;
    overflow: auto;
  }

  .contactsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background: #e9ecef;
    padding-top: 13px;
    padding-bottom: 13px;
    margin-bottom: 10px;
    &:hover {
      background: #bdbfc2;
    }
    .contactsLink {
      color: #1a202c;
      font-size: 17px;
      line-height: 22px;
      font-weight: 500;
      display: block;
      width: 100%;
      text-align: center;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .contactsIcon {
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
      &:hover {
        color: #1a202c !important;
      }
    }
  }

  .assistanceContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background: #e9ecef;
    padding-top: 13px;
    padding-bottom: 13px;
    &:hover {
      background: #bdbfc2;
    }
    .assistanceLink {
      color: #1a202c;
      font-size: 17px;
      line-height: 22px;
      font-weight: 500;
      display: block;
      width: 100%;
      text-align: center;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .assistanceIcon {
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
      &:hover {
        color: #1a202c !important;
      }
    }
  }
}
.footer {
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: $color-text;
}
.avatar {
  flex-shrink: 0;
  height: 48px;
  width: 48px;
  margin-right: 16px;
}
.user {
  display: flex;
  align-items: center;
}
.popover {
  width: 360px;

  :global {
    .ant-popover-inner {
      border-radius: 16px;
      box-shadow: 0px 0px 24px -8px rgba(45, 46, 64, 0.25);
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
.link {
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  border-radius: 8px;
  margin-right: 32px;
  color: $color-nav-text;
  &:last-child {
    margin-right: 0;
  }
  &:global(.active) {
    color: $color-nav-text-active;
    background: rgba(47, 128, 237, 0.2);
  }
}
.buttonNew {
  padding: 0 24px;
  height: 40px;
  font-weight: 500;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  border-radius: 8px;
  color: $color-nav-text;
  background: #2f80ed;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
