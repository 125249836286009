@import "variables";

.root{
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.component {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  border-radius: 16px;
  :global {
    .ant-card-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  color: $color-text;
  line-height: 36px;
  margin-top: 32px;
  margin-bottom: 0;
}

.description {
  font-size: 15px;
  color: $color-text;
  line-height: 19.2px;
  margin-top: 32px;
  margin-bottom: 0;
}

.actionContainer {
  margin-top: 32px;
  .backButton {
    background: #2f80ed;
    border-radius: 8px;
    height: 48px;
    font-size: 17px;
    line-height: 48px;
    padding: 0 24px;
    font-weight: bold;
    margin-right: 16px;
  }
}
