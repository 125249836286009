@import "variables";

.container {
  width: 100%;
}

.volumeBar {
  display: block;
  width: 100%;
  :global {
    .ant-slider-rail{
      background: #fff;
      opacity: 0.5;
    }
  }
}

.range {
  display: block;
  width: 100%;
}

.muteContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popover {
  z-index: 999;
  :global {
    .ant-popover-content{
      .ant-popover-arrow{
        display: none;
      }
      .ant-popover-inner {
        background-color: transparent!important;
        box-shadow: none;
        .ant-popover-inner-content{
          padding: 0;
        }
      }
    }
  }
}