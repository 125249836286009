@import 'variables';

.component {
  height: 330px;
  width: 320px;
  background: url('./creatorBorder.png') no-repeat;
  padding: 44px 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-text-5;
}
.icon {
  height: 80px;
  width: 80px;
  margin-bottom: 24px;
}
.title {
  font-size: 21px;
  line-height: 27px;
  margin-bottom: 8px;
}
.hint {
  font-size: 17px;
  line-height: 22px;
}
.button {
  margin-top: 24px;
}