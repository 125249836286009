.component {
  color: #fff;
  background: #2f80ed;
  border-radius: 8px;
  height: 48px;
  font-size: 17px;
  line-height: 19.98px;
  padding: 11px 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff;
  }
}

.small {
  height: 40px;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 17.63px;
  font-weight: 600;
}

.outlined {
  background: transparent;
  border: 1px solid #ced4da;
  color: #000;
  &:hover,
  &:focus,
  &:active {
    color: #000;
    background: transparent;
  }
}


