.editorRoot {
  font-size: 14px;
  padding: 2px;
  border: none;
  border-radius: 8px;
  color: #2d2e40;
  background-color: #f3f5f7;
}

.editorRootDisplayMode {
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin-top: 16px;
}

.editor {
  cursor: text;
  padding: 8px 18px;
}

.editorDisplayMode {
  padding: 16px;
}

.editorControlsContainer {
  background: #F2F2F2;
  border-radius: 0 0 8px 8px;
  padding: 12px;
}

.editorControls {
  font-size: 14px;
  user-select: none;
  display: inline;
}

.editorStyleButton {
  color: #15141A;
  cursor: pointer;
  margin-right: 16px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}

.activeButton {
  background-color: rgba(47, 128, 237, 0.2);
  border-radius: 4px;
  color: #2F80ED;
}

.linkInput {
  position: absolute;
  transform: translate(0, calc(-100% - 5px));
  padding: 14px;
  width: 280px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-shadow: 0 8px 16px rgba(45, 46, 64, 0.1);
  border-radius: 8px;
  z-index: 1;
}

.linkInputItem {
  margin: 0 0 8px;
  &:last-child {
    margin-bottom: 0;
  }
}

.linkInputOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}